// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  i18n: {
    supportedLanguages: ['en', 'en-US'],
    defaultLanguage: 'en-US'
  },
  env: 'qa',
  authEnv: 'qa',
  name: 'qa',
  appName: 'iot-device-manager',
  auth: {
    clientId: 'RoyWO2B8DJ5xPMR2m4C5i5yrZBAi5phi',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    redirectUri: window.location.origin,
    errorPath: '/error',
    applicationId: 'cbd07c81-2d57-4504-a06e-73913d46a1c2',
    defaultZonarRole: '8eb1447c-5f0c-4d6c-a1e0-f2cb3b808095',
    useRefreshTokens: true
  },
  datadog: {
    applicationId: 'c6409f95-666d-4cb9-90fb-0ab165667c35',
    clientToken: 'pubb6e17de02a7376d7a8f0565ead22a33b',
    site: 'us5.datadoghq.com'
  },
  slackurl:
    'https://join.slack.com/share/enQtNDM3NTQxNjg3ODE0Ny02ZTQ2NWIyMjA0ZTZhNjUwZjNkY2JiZWMwMTNiZTVmOTc2ZWMyZGZkZTg0MTlmNzE2YzA1YWQ4NzFlOTBiOGMy',
  serviceapi: 'https://api-qa.zonarsystems.net/core/v1beta2',
  iotcoreapi: 'https://api-qa.zonarsystems.net/iot/v1',
  iotprovisioningapi: 'https://api-qa.zonarsystems.net/iot/v1beta1',
  iotactivecompaniesapi:
    'https://api-qa.zonarsystems.net/iot/v1beta1/active-companies',
  packageapi: 'https://api-qa.zonarsystems.net/iot/v1beta1/package-configs',
  validationapi:
    'https://us-central1-cl-working-21ce.cloudfunctions.net/iot-device-config-status-qa',
  publishapi:
    'https://us-west2-cl-working-21ce.cloudfunctions.net/iot-config-publish-service-qa',
  datasetapi: 'https://api-qa.zonarsystems.net/iot/v1beta1',
  triggerapi: 'https://api-qa.zonarsystems.net/iot/v1beta1',
  lastRebootAPI: 'https://api-qa.zonarsystems.net/iot/v1',
  entityAssets: 'https://zonar-nonprod-qa.apigee.net/core/v1beta2/assets/',
  entityDevicesAssociations: 'https://api-qa.zonarsystems.net/iot/v1beta1',
  entityDevices:
    'https://zonar-nonprod-qa.apigee.net/core/v1beta2/devices?page=1&per_page=1000&status=ACTIVE',
  entityMapping:
    'https://iot-device-configuration-api.qa.zonarsystems.net/api/v1beta1/device-associations?page=1&per_page=1000',
  refreshConfigStatus:
    'https://us-central1-cl-production-635f.cloudfunctions.net/iot-device-config-status-qa',
  pgnsapi: 'https://api-qa.zonarsystems.net/iot/v1beta1/pgns',
  userId: 'https://api-qa.zonarsystems.net/core/v1/users',
  roles: 'https://api-qa.zonarsystems.net/core/v1beta2/userprofiles?userId=',
  httpInterceptor: {
    allowedList: [
      `https://api-qa.zonarsystems.net/core/v1beta2/*`,
      `https://iot-core-services-apis.qa.zonarsystems.net/api/v1`,
      'https://api-qa.zonarsystems.net/*',
      'https://api-qa.zonarsystems.net/*',
      'https://api.zonarsystems.net/*',
      'https://zonar-nonprod-qa.apigee.net/*',
      'https://device-api.production.zonarsystems.net/*',
      'https://iot-device-configuration-api.qa.zonarsystems.net/*',
      'https://iot-device-configuration-api.prod.zonarsystems.net/*',
      'https://us-central1-cl-working-21ce.cloudfunctions.net/*',
      'https://us-west2-cl-working-21ce.cloudfunctions.net/*',
      'https://rum.browser-intake-us5.datadoghq.com/*',
      'https://logs.browser-intake-us5.datadoghq.com/*',
      'https://iot-active-companies-api.qa.zonarsystems.net/*',
      'https://iot-asset-trigger-mappings-api.qa.zonarsystems.net/*'
    ]
  },
  production: false,
  stage: true,
  whitelistModification:
    'https://api-qa.zonarsystems.net/iot/v1beta1/active-companies/',
  nameSearch: 'https://api-qa.zonarsystems.net/core/v1beta2/companies?q=name:',
  accountCodeSearch:
    'https://api-qa.zonarsystems.net/core/v1beta2/divisions?q=legacyAccountCode:',
  assetTriggerMappingsSearch:
    'https://iot-asset-trigger-mappings-api.qa.zonarsystems.net/api/v1beta1/assettriggermappings',
  assetTriggerMappingsUpdate:
    'https://iot-asset-trigger-mappings-api.qa.zonarsystems.net/api/v1beta1/assettriggermappings',
  assetTriggerMappingsCreate:
    'https://iot-asset-trigger-mappings-api.qa.zonarsystems.net/api/v1beta1/assettriggermappings'
};
